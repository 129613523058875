import React from 'react'
import PropTypes from 'prop-types'

import Content from './Content'
import './PageHeader.css'

const PageHeader = ({
  title,
  subtitle,
  className = '',
  backgroundImage = false
}) => {
  const container = backgroundImage
    ? {
        backgroundImage,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }
    : {}

  return (
    <div className={`PageHeader relative ${className}`} style={container}>
      <div className="container relative">
        <h1 className="PageHeader--Title">{title}</h1>
        {subtitle && (
          <Content className="PageHeader--Subtitle" src={subtitle} />
        )}
      </div>
    </div>
  )
}

PageHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  className: PropTypes.string,
  backgroundImage: PropTypes.string
}

export default PageHeader
